@use "../config" as *;
@use "../mixins/type" as *;
@use "../mixins/space" as *;

.esri-ui {
  .esri-popup__main-container {
    border-color: $c-black;
    border-style: solid;
    border-width: 3px;
    color: $c-black;
    max-height: initial !important;
    width: 320px !important;
  }

  .esri-popup__footer,
  .esri-popup__button.esri-popup__button--dock {
    display: none;
  }

  .esri-popup__header {
    @include space(pt300);
  }
  .esri-popup__header,
  .esri-popup__content {
    @include space(ph300);
  }
  .esri-popup__content {
    @include space(pb300);
  }

  .esri-popup__header-container {
    pointer-events: none;
  }
  .esri-popup__header-title {
    @include space(pr400);
    @include type(100);
    font-family: $head-font;
  }

  .esri-popup__pointer-direction {
    background: $c-black;
  }

  .esri-view-width-xlarge .esri-popup__main-container {
    width: 240px;
  }

  .esri-popup__button {
    @include space(mr200);
    @include space(mt200);
    line-height: 0;
    position: absolute;
    top: 0;
    right: 0;
    &:hover {
      background: transparent;
    }
  }
  .esri-popup__icon {
    height: 22px;
    width: 22px;
  }
  .esri-icon-close:before {
    @include type(200);
    font-family: 'iconfont' !important;
    font-size: inherit;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    color: $c-black;
    speak: none;
    text-transform: none;
    content: '\e901';

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.popup__cover {
  display: block;
  line-height: 0;
}
.popup__link {
  display: block;
}
