@use '../config' as *;
@use '../mixins/breakpoint' as *;
@use '../mixins/space' as *;
@use '../mixins/type' as *;

// dialog musthaves
[data-a11y-dialog-native] .ck-menu-overlay {
  display: none;
}

.ck-menu[aria-hidden='true'] {
  display: none;
}

.ck-menu {
  // dialog children
  .ck-menu-overlay {
    background-color: $c-blue;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }
  dialog[open] {
    display: flex;
  }
  dialog::backdrop {
    background-color: $c-blue;
  }
  .ck-menu-dialog {
    align-items: center;
    background: $c-blue;
    border: none;
    bottom: 0;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  // content children
  .ck-menu-head {
    z-index: 20;
  }
  .ck-menu-body {
    z-index: 20;
  }
  .ck-menu-list {
  }
  .ck-menu-item {
    @include space(mb300);
    @include space(pv100);
    color: $c-white;
    display: block;
  }
  .ck-menu-link {
    @include type(500);
    color: $c-white;
    cursor: pointer;
    font-family: $head-font;
    &:focus,
    &:hover,
    &.active {
      text-decoration: underline;
    }
  }
  .ck-menu-languages {
    @include type(300);
    font-family: $body-font;
    letter-spacing: $tracking-m;
    text-transform: uppercase;
  }
}
