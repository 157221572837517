@use "../config" as *;
@use "./ofMixins/interpolate.scss" as *;

@mixin space($args) {
  $props: (
    m: margin,
    p: padding,
    b: border,
  );

  $sides: (
    a: all,
    b: bottom,
    h: horizontal,
    l: left,
    r: right,
    t: top,
    v: vertical,
  );

  $prop: map-get($props, str-slice($args, 1, 1));
  $side: map-get($sides, str-slice($args, 2, 2));
  $space: map-get($spaces, #{'space' + str-slice($args, 3, 3) + '00'});

  @if ($side == 'horizontal') {
    @include interpolate((#{$prop}-left, #{$prop}-right), nth($space, 1), nth($space, 2));
  } @else if ($side == 'vertical') {
    @include interpolate((#{$prop}-top, #{$prop}-bottom), nth($space, 1), nth($space, 2));
  } @else if ($side == 'all') {
    @include interpolate((#{$prop}), nth($space, 1), nth($space, 2));
  } @else {
    @include interpolate((#{$prop}-#{$side}), nth($space, 1), nth($space, 2));
  }
}

// @include size(pa100);
