@use "../config" as *;
@use "../mixins/breakpoint" as *;
@use "../mixins/size" as *;
@use "../mixins/space" as *;
@use "../mixins/type" as *;

.ck-layout--library {
  .ck-body {
    padding-right: 11%;
    @include bp(desktopUp) {
      padding-left: 40%;
    }
  }

  .ck-article-bd {
    @include type(100);
    display: block;
    transition: padding $time-m;
  }
  .ck-article-text {
    @include space(mb400);
  }
  .ck-article-link {
    text-decoration: underline;
  }

  .ck-separator {
    @include space(mv400);
  }
}
