@use "../config" as *;
@use "../mixins/breakpoint" as *;
@use "../mixins/space" as *;
@use "../mixins/type" as *;

.ck-layout--newhome {
  @include space(pb000);
  .ck-slider-body {
    align-items: flex-start;
  }
  .ck-section--fold {
    padding-right: 11%;
    .ck-slider-page-1 {
      flex: 0 0 8%;
    }
    .ck-slider-page-2 {
      flex: 0 0 20%;
    }
    .ck-slider-page-3 {
      flex: 0 0 17%;
    }
    .ck-slider-page-4 {
      flex: 0 0 55%;
    }
    .ck-slider-page-5 {
      flex: 0 0 6%;
    }
  }

  .ck-slider-item {
    // background-color: darkseagreen;
  }
  .ck-slide.ck-slide--main {
    // background: palevioletred;
    height: 100%;
    position: relative;
    width: 100%;
    @include bp(tabletUp) {
      // align-items: flex-start;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .ck-slide-copy {
      @include space(pr400);
      // background-color: peachpuff;
      flex: 1 0 40%;
      overflow: hidden;
      position: relative;
    }
    .ck-slide-image {
      // background-color: darkgoldenrod;
      position: relative;
      @include bp(tabletUp) {
        flex: 0 1 55%;
        margin-right: -1px;
      }
    }
  }
  .ck-slide.ck-slide--secondary {
    // background: palevioletred;
    display: flex;
    height: 100%;
    position: relative;
    width: 100%;
  }

  .ck-slide-title {
    @include type(600);
    font-family: $head-font;
    word-wrap: break-word;
  }
  .ck-slide-text {
    @include type(200);
    @include space(mt400);
    @include bp(tabletUp) {
      @include type(300);
    }
  }
  .ck-slide-link {
    @include space(mv400);
    @include type(500);
  }
  .ck-slide-image-link {
    display: block;
    outline: none;
    &:focus {
      outline: none;
    }
  }
  .ck-slide-insert {
    position: relative;
    width: 100%;
  }

  .ck-slide-grid {
    // background-color: darkslategray;
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
    min-height: 100%;
    &.ck-slide-grid--v {
      flex-direction: column;
      & > .ck-grid-el {
        flex: 0 0 66%;
        & > .ck-slider-card {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
        }
      }
      & > .ck-grid-el.ck-grid-el--bottom {
        flex: 0 0 33%;
        & > .ck-slider-card {
          display: flex;
          flex-direction: row;
          float: right;
          justify-content: flex-end;
          width: 66%;
        }
      }
    }
    &.ck-slide-grid--h {
      flex-direction: row;
      & > .ck-grid-el--right {
        flex: 0 0 33%;
      }
    }
  }

  .ck-slider-card {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    & > .ck-insert {
      max-width: 300px;
    }
    & > h2 {
      @include space(ph300);
      @include type(100);
    }
  }

  .ck-grid-el--right .ck-slider-card {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    height: 100%;
    h2 {
      @include space(ph000);
      @include space(pv300);
    }
  }

  .ck-slide-grid--h {
    .ck-insert {
      max-width: initial;
    }
  }
  .ck-slide-grid--v {
    .ck-insert {
      flex: 0 0 66%;
    }
  }

  @include bp(phone) {
    .ck-slide-grid.ck-slide-grid--h {
      flex-direction: column-reverse;
    }
    .ck-slide-grid.ck-slide-grid--v {
      flex-direction: row-reverse;
    }
    .ck-slide-grid.ck-slide-grid--v > .ck-grid-el > .ck-slider-card {
      flex-direction: column;
    }
    .ck-slide-grid.ck-slide-grid--v > .ck-grid-el {
      flex: 45%;
    }
    .ck-slider-card > h2 {
      @include space(ph000);
      @include space(pv300);
    }
    .ck-slide-grid.ck-slide-grid--v > .ck-grid-el.ck-grid-el--bottom > .ck-slider-card {
      @include space(pr4);
      flex-direction: column;
      justify-content: center;
      width: auto;
      & > h2 {
        @include space(pv3);
      }
    }
    .ck-grid-el.ck-grid-el--left {
      flex: 0 0 50%;
    }
    .ck-slide-grid.ck-slide-grid--v > .ck-grid-el.ck-grid-el--bottom {
      align-self: flex-end;
      flex: 0 0 45%;
    }
  }
}
