@use '../mixins/type' as *;
@use '../mixins/space' as *;

.ck-layout--locations {
  .ck-wrap {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .ck-body {
    flex: 1 1 89%;
  }
}
