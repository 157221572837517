@use "../config" as *;

.ck-actionable {
  color: inherit;
  cursor: pointer;
  transition: box-shadow $time-m, background $time-m, color $time-m, border $time-m;
  &:active,
  &:focus,
  &:hover {
    outline: none;
  }
  &:focus {
    background: $c-blue;
    box-shadow: 0 0 0 5px $c-blue;
    color: $c-white !important;
  }
  .ck-force-negative & {
    color: $c-white;
    background: transparent;
    &:focus {
      background: $c-white;
      box-shadow: 0 0 0 5px $c-white;
      color: $c-blue !important;
    }
  }
}

.ck-link {
  display: inline-block;
  text-decoration: none;
  white-space: normal;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.ck-button {
  appearance: none;
  background: transparent;
  border-color: transparent;
  border-style: solid;
  border-width: 0 0 2px 0;
  color: inherit;
  display: inline-block;
  font-size: inherit;
  letter-spacing: $tracking-s;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  &:active,
  &:focus,
  &:hover {
    border-color: currentColor;
  }
  &.ck-force-vertical {
    border-width: 0 0 0 2px;
  }
}

.ck-iconbutton {
  @extend .ck-button;
  border: none;
  line-height: 0;
  text-decoration: none;
}
