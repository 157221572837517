@use "../mixins/type" as *;

[class^='ck-icon-'],
[class*=' ck-icon-'] {
  font-family: 'iconfont' !important;
  font-size: inherit;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ck-icon--back:before {
  content: '\e904';
}
.ck-icon--plus:before {
  content: '\ed5d';
}
.ck-icon--minus:before {
  content: '\ed5e';
}
.ck-icon--next:before {
  content: '\edbd';
}
.ck-icon--prev:before {
  content: '\edc5';
}
.ck-icon--close:before {
  content: '\e901';
}
.ck-icon--menu:before {
  content: '\e900';
}
.ck-icon--left:before {
  content: '\e902';
}
.ck-icon--right:before {
  content: '\e903';
}
