@use "../config" as *;
@use "../mixins/breakpoint" as *;
@use "../mixins/space" as *;
@use "../mixins/type" as *;

.ck-layout {
  @include space(pb400);
  @include space(ph400);
  @include type(300);
  background: $c-grey;
  color: $c-black;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  @include bp(desktopUp) {
    @include space(pb500);
    @include space(ph500);
  }
}

.ck-wrap {
  @include space(pt600);
  margin-left: auto;
  margin-right: auto;
  max-width: $mwidth-h;
  position: relative;
  z-index: 1;
  @include bp(desktopUp) {
    @include space(pt700);
  }
}

.ck-side {
  @include space(pr200);
  @include type(200);
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  flex: 0 0 11%;
  justify-content: space-between;
  position: relative;
  z-index: 50;
}
