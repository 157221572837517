@use './styles/config' as *;

// fonts
@use './styles/fonts';

// reset
@use './styles/vendor/reset';
@use './styles/normalize';

// components
@use './styles/components/accordions';
@use './styles/components/actionables';
@use './styles/components/brandmarks';
@use './styles/components/icons';
@use './styles/components/inputs';
@use './styles/components/inserts';
@use './styles/components/mappopup';
@use './styles/components/maps';
@use './styles/components/paginations';
@use './styles/components/separators';
@use './styles/components/tags';
@use './styles/components/thumbs';
@use './styles/components/tiles';
@use './styles/components/tooltips';

// partials
@use './styles/partials/decor';
@use './styles/partials/footer';
@use './styles/partials/layout';
@use './styles/partials/loader';
@use './styles/partials/menu';
@use './styles/partials/offcanvas';
@use './styles/partials/sidenav';
@use './styles/partials/slider';
@use './styles/partials/topbar';

// layouts
@use './styles/layouts/about';
@use './styles/layouts/contact';
@use './styles/layouts/home';
@use './styles/layouts/library';
@use './styles/layouts/locations';
@use './styles/layouts/newhome';
@use './styles/layouts/object';
@use './styles/layouts/objects';

// vendors

.transition-fade {
  transition: 0.4s;
  opacity: 1;
}

html.is-animating .transition-fade {
  opacity: 0;
}

// helpers

.ck-force-disabled.ck-force-disabled {
  opacity: 0.25;
}
.ck-force-vertical.ck-force-vertical {
  writing-mode: vertical-rl;
}
.ck-force-negative.ck-force-negative {
  background: $c-blue;
  color: $c-white;
}
.ck-force-sticky.ck-force-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  &:before,
  &:after {
    content: '';
    display: table;
  }
}

.esri-view-root:focus,
.esri-view-surface:focus,
#MapEl:focus,
#EsriMapEl:focus,
#EsriMapEl canvas:focus {
  outline: none;
}

body {
  transition: opacity 2s;
  .fadeIn {
    opacity: 1;
  }
  .fadeOut {
    opacity: 0;
  }
  .noscroll {
    position: fixed;
    overflow: hidden;
  }
}
