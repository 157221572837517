@use '../mixins/type' as *;
@use '../mixins/space' as *;

.ck-tags {
}

.ck-tag {
  @include space(mb200);
  @include type(200);
  display: block;
  position: relative;
}

.ck-tag-link {
  display: block;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
