@use '../config' as *;
@use '../mixins/breakpoint' as *;
@use '../mixins/space' as *;
@use '../mixins/type' as *;

.ck-tile {
  display: flex;
  flex-direction: column-reverse;
}
.ck-tile-title {
  @include space(mt200);
  @include type(100);
  display: none;
  opacity: 0;
  text-align: left;
  transition: opacity $time-m;
  .ck-tile:focus &,
  .ck-tile:hover & {
    opacity: 1;
  }
  @include bp(desktopUp) {
    display: block;
  }
}
.ck-tile-thumb {
  display: block;
}
