@use "../config" as *;
@use "../mixins/breakpoint" as *;
@use "../mixins/space" as *;
@use "../mixins/type" as *;
@use "../vendor/slider" as *;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ck-slider {
  // background: cornflowerblue;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  user-select: none;
  width: 100%;
}
.ck-slider-body {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  & > div {
    height: 100%;
    position: relative;
    width: 100%;
  }
}
.ck-slider-item {
  align-items: flex-end;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  width: 100%;
  // & > div {
  //   align-items: flex-end;
  //   display: flex;
  //   flex-direction: column;
  //   height: 100%;
  //   justify-content: flex-end;
  //   width: 100%;
  // }
}

// pagination
.ck-slider-pagination {
  position: relative;
  width: 100%;
  z-index: 1;
}
.ck-slider-pages {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  &.ck-slider-pages--count2 > * {
    flex: 0 0 50%;
  }
  &.ck-slider-pages--count3 > * {
    flex: 0 0 33.333%;
  }
  &.ck-slider-pages--count4 > * {
    flex: 0 0 25%;
  }
  &.ck-slider-pages--count5 > * {
    flex: 0 0 20%;
  }
}
.ck-slider-page {
  border-top: 2px solid transparent;
  transition: border-color $time-m;
  &:hover,
  &.tns-nav-active {
    border-color: $c-black;
  }
}
.ck-slider-link {
  @include space(pv200);
  @include space(pl200);
  font-size: 15px;
  color: $c-grey3;
  cursor: pointer;
  display: block;
  font-family: $body-font;
  transition: color $time-m;
  &:hover,
  .tns-nav-active & {
    color: $c-black;
  }
}

// Overwrites

.tns-fadeIn {
  -webkit-animation-name: fadeIn;
  -webkit-transform-origin: center;
  animation-name: fadeIn;
  transform-origin: center;
}
.tns-fadeOut {
  -webkit-animation-name: fadeOut;
  -webkit-transform-origin: center;
  animation-name: fadeOut;
  transform-origin: center;
}
// @include bp(phone) {
//   .tns-ovh {
//     overflow: visible;
//   }
// }
.tns-outer,
.tns-ovh,
.tns-item,
.tns-inner,
.tns-slider {
  height: 100%;
  position: relative;
  // padding: 0 !important;
}
