@use "../config" as *;
@use "../mixins/breakpoint" as *;
@use "../mixins/size" as *;
@use "../mixins/space" as *;
@use "../mixins/type" as *;

.ck-topbar {
  @include space(pa400);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  @include bp(desktopUp) {
    @include space(pa500);
  }
}

.ck-topbar-side {
  @include size(h300);
  @include type(400);
  flex: 0 0 50%;
  position: relative;
}

.ck-topbar-side > * {
  pointer-events: initial;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.ck-topbar-side--lx > * {
  left: 0;
}

.ck-topbar-side--rx > * {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  right: 0;
}

.ck-language-switch {
  @include space(mr400);
  @include type(300);
}
