@use '../config' as *;
@use '../mixins/breakpoint' as *;
@use '../mixins/space' as *;
@use '../mixins/type' as *;

// dialog musthaves
[data-a11y-dialog-native] .ck-offcanvas-overlay {
  display: none;
}

.ck-offcanvas[aria-hidden='true'] {
  display: none;
}

.ck-offcanvas {
  // dialog children
  .ck-offcanvas-overlay {
    background-color: rgba(255, 255, 255, 0.2);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }
  dialog[open] {
    display: block;
    z-index: 1000;
  }
  dialog::backdrop {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .ck-offcanvas-dialog {
    @include space(pt600);
    @include space(ph000);
    border: none;
    bottom: 0;
    height: 100%;
    left: auto;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    width: 320px;
    z-index: 10;
    @include bp(desktopUp) {
      @include space(pt700);
    }
  }

  // content children
  .ck-offcanvas-head {
    @include space(pa400);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ck-offcanvas-body {
    @include space(pb400);
    @include space(ph500);
  }
  .ck-offcanvas-list {
  }
  .ck-offcanvas-item {
    @include space(mb300);
    @include space(pv100);
    display: block;
  }
  .ck-offcanvas-link {
    @include type(500);
    color: $c-white;
    cursor: pointer;
    font-family: $head-font;
    &:focus,
    &:hover,
    &.active {
      text-decoration: underline;
    }
  }
}
