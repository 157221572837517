@use "../config" as *;
@use "../mixins/breakpoint" as *;
@use "../mixins/size" as *;
@use "../mixins/space" as *;
@use "../mixins/type" as *;

.ck-layout--about {
  // SECTIONS
  .ck-section--body,
  .ck-section--fold,
  .ck-section--intro,
  .ck-section--outro,
  .ck-section--team {
    @include space(mb400);
    @include bp(desktopUp) {
      @include space(mb500);
    }
  }

  //
  .ck-section--fold {
    .ck-section-title {
      @include type(800);
      font-family: $head-font;
      width: 100%;
      span {
        // display: block;
      }
      @include bp(desktopUp) {
        padding-left: 25%;
      }
    }
  }

  // INTRO
  .ck-section--intro {
    @include type(300);
    padding-right: 11%;
    @include bp(desktopUp) {
      padding-left: 40%;
    }
  }

  // TEAM
  .ck-section--team {
    align-content: stretch;
    align-items: stretch;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    @include space(pb000);
    .ck-team-members {
      @include bp(tabletUp) {
        // align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
    .ck-team-member {
      position: relative;
      @include space(mb500);
      @include bp(phone) {
        padding-right: 11%;
      }
      @include bp(tabletUp) {
        &:nth-child(2n) {
          @include space(pl500);
          flex: 0 0 49%;
          text-align: center;
        }
        &:nth-child(2n + 1) {
          @include space(pr500);
          flex: 0 0 40%;
        }
        &:nth-child(4n + 3) {
          text-align: right;
        }
        &:nth-child(4n + 4) {
          text-align: right;
        }
      }
      // @include bp(desktopUp) {
      //   &:nth-child(2n + 1) {
      //     top: -100px;
      //   }
      // }
    }
    .ck-accordion-trigger {
      @include space(pa000);
      display: inline-block;
      width: auto;
      &:after {
        display: none;
        content: none;
      }
    }
    .ck-team-member-name {
      @include space(mt200);
      @include type(300);
    }
    .ck-team-member-bio {
      @include type(100);
      text-align: left;
    }
  }
  .ck-section--body {
    @include type(400);
    padding-right: 11%;
  }
  .ck-section--outro {
    @include type(300);
    padding-right: 11%;
    @include bp(desktopUp) {
      padding-left: 40%;
    }
  }

  // CREDITS
  .ck-section--credits {
    @include type(100);
    @include space(pb700);
    display: block;
    padding-right: 11%;
    @include bp(desktopUp) {
      padding-left: 40%;
    }
    .ck-section-title {
      @include type(300);
      @include space(mb300);
    }
    .ck-partner {
      position: relative;
      @include bp(tabletUp) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      &:not(:first-child) {
        @include space(mt500);
        @include space(pt500);
        &:after {
          @include size(w300);
          border-color: $c-black;
          border-style: solid;
          border-width: 2px 0 0;
          content: ' ';
          display: block;
          position: absolute;
          top: 0;
        }
      }
    }
    .ck-partner-link {
      color: $c-greydark;
      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
    .ck-partner-logo {
      display: inline-block;
      @include bp(phone) {
        @include space(mt300);
      }
      @include bp(tabletUp) {
        @include space(ml300);
      }
    }

    .ck-separator {
      @include space(mv500);
      border-width: 2px 0 0;
    }
  }
}
