@use "../config" as *;
@use "../mixins/type" as *;

.ck-brandmark {
  @include type(300);
  color: inherit;
  display: inline-block;
  font-family: $head-font;
  line-height: 1em;
  overflow: visible;
  white-space: normal;
  & > * {
    clear: both;
    display: block;
  }
}
