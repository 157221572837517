@use "../config" as *;
@use "../mixins/breakpoint" as *;
@use "../mixins/space" as *;
@use "../mixins/type" as *;

.ck-layout--home {
  .ck-section--fold {
    padding-right: 11%;
    .ck-slider-item > div {
      align-items: flex-start;
      @include bp(desktopUp) {
        @include space(pt600);
      }
    }
    .ck-slider-page-1 {
      flex: 0 0 5%;
    }
    .ck-slider-page-2 {
      flex: 0 0 23%;
    }
    .ck-slider-page-3 {
      flex: 0 0 17%;
    }
    .ck-slider-page-4 {
      flex: 0 0 55%;
    }
    .ck-slider-page-5 {
      flex: 0 0 6%;
    }
    .ck-slider-page-6 {
      flex: 0 0 6%;
    }
    .ck-thumb-insert {
      max-height: 60vh;
    }
    // .ck-thumb {
    //   width: 100%;
    // }
    .ck-slider-item-text {
      @include space(mb400);
      @include type(700);
      color: $c-blue;
      font-family: $head-font;
      text-align: left;
      @include space(mb000);
      position: absolute;
      right: 0;
      top: 0;
      z-index: 20;
      width: 72%;
      @include bp(desktopUp) {
        width: 55.3%;
      }
    }
  }
  .ck-section--body {
    @include space(pv400);
    @include bp(desktopUp) {
      @include space(pv600);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .ck-body-side {
      @include bp(desktopUp) {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        flex: 0 0 25%;
        justify-content: center;
      }
    }
    .ck-body-title {
      @include space(mb400);
      @include type(900);
      font-family: $head-font;
      @include bp(desktopUp) {
        @include space(mb000);
        transform: rotate(180deg);
        writing-mode: vertical-lr;
      }
    }
    .ck-body-main {
      @include bp(desktopUp) {
        padding-right: 11%;
      }
    }
    .ck-body-text {
      @include space(mb400);
      padding-right: 11%;
      @include bp(desktopUp) {
        @include space(mb600);
        padding-left: 23.5%;
        padding-right: 0;
      }
    }
    .ck-body-image {
      padding-right: 11%;
      @include bp(desktopUp) {
        padding-right: 0;
        padding-right: 23.5%;
      }
    }
  }
}
