@use "../mixins/breakpoint" as *;
@use "../mixins/space" as *;
@use "../mixins/type" as *;

.ck-layout--contact {
  .ck-body {
    padding-right: 11%;
    @include bp(desktopUp) {
      padding-left: 40%;
    }
  }

  .ck-contact-title {
    @include space(mb600);
    @include type(400);
  }
  .ck-contact-email {
    @include space(mb400);
  }
}
