@use "../config" as *;
@use "../mixins/type" as *;

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.ck-loader {
  align-content: center;
  align-items: center;
  bottom: 0;
  display: none;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 1;
  position: fixed;
  right: 0;
  text-align: left;
  top: 0;
  transition: opacity 2s;
  width: 100%;
  z-index: 100;
  &.isVisible {
    display: flex;
  }
}

.ck-loader-title {
  @include type(800);
  font-family: $head-font;
  opacity: 1;
  position: relative;
  transition: opacity 1s;
  z-index: 1;
  span {
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-iteration-count: 1 !important;
    backface-visibility: hidden;
    display: block;
    transition-duration: 1ms !important;
    &:first-child {
      animation-name: fadeIn;
    }
    &:last-child {
      animation-name: fadeIn;
      animation-delay: 0.5s;
    }
  }
}

.ck-loader.isVisible {
  .ck-loader-title span:first-child {
  }
  .ck-loader-title span:last-child {
  }
}

.ck-loader.isDisappearing {
  opacity: 0;
  .ck-loader-title {
    opacity: 0;
  }
  .ck-loader-title span:first-child {
  }
  .ck-loader-title span:last-child {
  }
}

.ck-loader.isHidden {
  .ck-loader-title span:first-child {
  }
  .ck-loader-title span:last-child {
  }
}
