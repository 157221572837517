@use "../config" as *;
@use "../mixins/breakpoint" as *;
@use "../mixins/size" as *;
@use "../mixins/space" as *;
@use "../mixins/type" as *;

.ck-layout--object {
  .ck-wrap {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .ck-body {
    display: flex;
    flex-direction: column;
    flex: 1 1 89%;
    justify-content: space-between;
  }

  .ck-section--fold {
    @include space(mb600);
    @include type(200);
    display: flex;
    flex-direction: column-reverse;
    @include bp(desktopUp) {
      @include space(mb700);
      align-content: stretch;
      align-items: stretch;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      position: relative;
    }
  }

  // text
  .ck-object-text {
    // background: papayawhip;
    @include bp(desktopUp) {
      @include space(pr400);
      display: flex;
      flex-direction: column;
      flex: 0 0 28%;
      justify-content: space-between;
    }
    .ck-object-author {
      @include space(mb300);
      @include type(100);
    }
    .ck-object-title {
      @include space(mb300);
      @include type(400);
      font-family: $head-font;
      hyphens: auto;
      @include bp(desktopUp) {
        @include space(mb500);
      }
    }
    .ck-object-inventory {
      @include space(mb200);
      @include bp(desktopUp) {
        @include space(mb300);
      }
    }
    .ck-separator {
      @include size(w100);
      @include space(mv300);
      border-width: 2px 0 0;
    }

    .ck-object-date,
    .ck-object-address,
    .ck-object-source {
      @include type(100);
    }
  }

  // gallery
  .ck-object-gallery {
    @include space(mb400);
    .ck-thumb-insert {
      min-width: 100%;
    }
    .ck-thumb--landscape {
      width: 100%;
    }
    @include bp(desktopUp) {
      @include space(mb000);
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      flex: 0 0 72%;
      justify-content: flex-end;
    }
  }
  @include bp(phone) {
    .ck-section--fold {
      flex-direction: column-reverse;
    }
    .ck-object-intro {
      @include space(mb600);
    }
  }

  .ck-map-cta {
    @include space(pt300);
  }
}
