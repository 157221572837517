@use "../config" as *;
@use "../mixins/breakpoint" as *;

.ck-thumb {
  display: inline-block;
  line-height: 0;
  position: relative;
  > * {
    min-height: 100%;
    min-width: 100%;
  }
  .ck-thumb-insert {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
  .ck-thumb-overlay {
    background-blend-mode: luminosity;
    background-color: #11078d;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    bottom: 0;
    content: ' ';
    display: block;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity $time-m;
  }
  &:hover .ck-thumb-overlay {
    opacity: 0;
  }
}
