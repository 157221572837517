@use '../config' as *;
@use '../mixins/type' as *;

.tippy-popper {
  @include type(100);
  letter-spacing: $tracking-s;
  text-transform: uppercase;
  & * {
    font-family: $body-font;
  }
  & > * {
    padding-top: 2px;
  }
}
