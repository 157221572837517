@use "../config" as *;
@use "../mixins/space" as *;

.ck-separator {
  @include space(mh000);
  @include space(mv000);
  @include space(pa000);
  border-color: $c-blue;
  border-style: solid;
  border-width: 1px 0 0;
  display: block;
  .ck-force-negative & {
    border-color: $c-white;
  }
}
