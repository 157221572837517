@use '../mixins/breakpoint' as *;
@use '../mixins/space' as *;
@use '../mixins/type' as *;

.ck-sidenavs {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
}

.ck-sidenav {
  &.ck-sidenav--xcuts {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    & > * {
      @include space(mb400);
    }
    .ck-button {
      min-width: 1.5em;
    }
  }
  &.ck-sidenav--pager {
    @include type(400);
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include bp(desktopUp) {
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
    }
    & > * {
      @include space(mt200);
    }
  }
}
