@use "../config" as *;
@use "../mixins/breakpoint" as *;
@use "../mixins/space" as *;

.ck-decor {
  @include space(ph400);
  bottom: 0;
  left: 0;
  opacity: 1;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(0) translateY(0);
  transition: opacity $time-m, transform $time-m;
  user-select: none;
  z-index: 0;
  @include bp(desktopUp) {
    @include space(ph500);
  }
  .is-changing & {
    opacity: 0;
  }
}

.ck-decor-el {
  border-color: $c-grey2;
  border-style: solid;
  .ck-force-negative & {
    border-color: $c-blue-light;
  }
}

.ck-decor--x {
  @include bp(phoneUp) {
    @include space(pv400);
  }
  @include bp(desktopUp) {
    @include space(pv500);
  }

  .ck-decor-els {
    @include space(mv600);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }
  .ck-decor-el {
    .is-changing & {
      transform: translateX(-100%);
    }
    @include space(mt600);
    top: 1px;
    border-width: 1px 0;
    height: 50%;
    position: relative;
    width: 100%;
  }
}

.ck-decor--y {
  .ck-decor-els {
    height: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: $mwidth-h;
  }
  .ck-decor-el {
    border-width: 0 1px;
    height: 100%;
    position: absolute;
    .is-changing & {
      transform: translateY(-100%);
    }
  }
  .ck-decor-el--1 {
    left: 0;
    width: 25%;
  }
  .ck-decor-el--2 {
    margin-right: -1px;
    right: 11%;
    width: 49%;
    @include bp(phone) {
      border-left: none;
    }
  }
}
