@use "../mixins/space" as *;
@use "../mixins/type" as *;
@use '../config' as *;

.ck-input {
  @include space(mb400);
  @include space(ph000);
  @include space(pv100);
  @include type(200);
  appearance: none;
  background: transparent;
  border-color: rgba(255, 255, 255, 0.5);
  border-style: solid;
  border-width: 0 0 2px 0;
  color: white;
  font-family: $body-font;
  width: 100%;
  &::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  &:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
}
