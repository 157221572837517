@use "../config" as *;
@use "../mixins/size" as *;
@use "../mixins/space" as *;

.ck-accordion {
  .ck-accordion-head {
    display: block;
    width: 100%;
  }
  .ck-accordion-trigger {
    appearance: none;
    background: transparent;
    border: none;
    cursor: pointer;
    display: block;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: left;
    width: 100%;
  }
}

.ck-accordion-mounted {
  .ck-accordion-trigger {
    @include space(pr400);
  }
  .ck-accordion-trigger:after {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    @include size(w200);
    content: '\ed5d';
    display: block;
    font-family: 'iconfont' !important;
    font-size: inherit;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    height: 100%;
    line-height: 1;
    position: absolute;
    right: 0;
    speak: none;
    text-align: right;
    text-transform: none;
    top: 50%;
    transform: translateY(-50%);
  }
  .ck-accordion-head {
    @include space(pa000);
  }
  .ck-accordion-head--open .ck-accordion-trigger:after {
    content: '\ed5e';
  }
  .ck-accordion-body {
    @include space(ma000);
    @include space(pa000);
    height: 0px;
    opacity: 0;
    overflow: hidden;
    transition: opacity $time-m;
    visibility: hidden;
  }
  .ck-accordion-body--open {
    @include space(pt400);
    height: auto;
    opacity: 1;
    visibility: visible;
  }
}
