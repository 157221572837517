@font-face {
  font-family: 'FreightDispProBlack-Regular';
  src: url('fonts/typefaces/3A9048_0_0.eot');
  src: url('fonts/typefaces/3A9048_0_0.eot?#iefix') format('embedded-opentype'),
    url('fonts/typefaces/3A9048_0_0.woff2') format('woff2'), url('fonts/typefaces/3A9048_0_0.woff') format('woff'),
    url('fonts/typefaces/3A9048_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'DINNextLTPro-Medium';
  src: url('fonts/typefaces/3A9048_1_0.eot');
  src: url('fonts/typefaces/3A9048_1_0.eot?#iefix') format('embedded-opentype'),
    url('fonts/typefaces/3A9048_1_0.woff2') format('woff2'), url('fonts/typefaces/3A9048_1_0.woff') format('woff'),
    url('fonts/typefaces/3A9048_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'iconfont';
  src: url('fonts/iconfont/fonts/iconfont.eot?he4c9x');
  src: url('fonts/iconfont/fonts/iconfont.eot?he4c9x#iefix') format('embedded-opentype'),
    url('fonts/iconfont/fonts/iconfont.ttf?he4c9x') format('truetype'),
    url('fonts/iconfont/fonts/iconfont.woff?he4c9x') format('woff'),
    url('fonts/iconfont/fonts/iconfont.svg?he4c9x#iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
