// colors
$c-black: #050505 !default;
$c-blue: #040126 !default;
$c-blue-light: #141037 !default; // rgba(255, 255, 255, 0.05)
$c-greydark: #757575 !default;
$c-white: #fff !default;
$c-grey3: #d0d0d0 !default;
$c-grey2: #e5e5e5 !default;
$c-grey: #f2f2f2 !default;

$shadow: rgba(0, 0, 0, 0.5) !default;

// fonts
$head-font: 'FreightDispProBlack-Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$body-font: 'DINNextLTPro-Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif !default;

// leading
$leading-h: 1.8em !default;
$leading-l: 1.6em !default;
$leading-m: 1.4em !default;
$leading-s: 1.2em !default;
$leading-x: 1em !default;

// fsizes
$fsize-h: 30px 60px !default;
$fsize-l: 24px 48px !default;
$fsize-m: 20px 26px !default;
$fsize-s: 18px 22px !default;
$fsize-x: 16px 20px !default;

$fsizes: (
  fsize100: 16px 20px $leading-s,
  fsize200: 18px 22px $leading-s,
  fsize300: 20px 26px $leading-m,
  fsize400: 24px 38px $leading-x,
  fsize500: 30px 60px $leading-x,
  fsize600: 36px 76px $leading-x,
  fsize700: 42px 96px $leading-x,
  fsize800: 48px 120px $leading-x,
  fsize900: 54px 148px $leading-x,
);

// sizes
$sizes: (
  size000: 12px 16px,
  size100: 16px 24px,
  size200: 24px 40px,
  size300: 32px 64px,
  size400: 48px 96px,
  size500: 64px 128px,
  size600: 80px 160px,
  size700: 96px 192px,
);

// spaces
$spaces: (
  space000: 0px 0px,
  space100: 3px 4px,
  space200: 6px 8px,
  space300: 12px 16px,
  space400: 24px 32px,
  space500: 38px 64px,
  space600: 76px 128px,
  space700: 114px 192px,
  space800: 128px 256px,
);

// times
$time-l: 0.5s !default;
$time-m: 0.3s !default;
$time-s: 0.1s !default;

// tracking
$tracking-l: 0.32em !default;
$tracking-m: 0.16em !default;
$tracking-s: 0.08em !default;

// max-widths
$mwidth-h: 1500px !default;
$mwidth-l: 1300px !default;
$mwidth-m: 1100px !default;
$mwidth-s: 900px !default;
