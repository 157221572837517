@use 'config' as *;
@use 'mixins/type' as *;

* {
  box-sizing: border-box;
}

html,
body {
  background-color: $c-grey;
  min-height: 100vh;
}

body {
  @include type(100);
  font-family: $body-font;
  font-style: normal;
  font-weight: normal;
}

a {
  color: $c-blue;
}

button {
  appearance: none;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  padding: 0;
}
