@use "../mixins/space" as *;
@use "../mixins/type" as *;

.ck-pagination {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;
  z-index: 1000;
}

.ck-pagination-item {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.ck-pagination-icon {
  @include type(400);
  &.ck-pagination-icon--next {
    @include space(ml200);
  }
  &.ck-pagination-icon--prev {
    @include space(mr200);
  }
}
