@use "../config" as *;
@use "../mixins/breakpoint" as *;
@use "../mixins/space" as *;
@use "../mixins/type" as *;

.ck-footer {
  @include space(ph400);
  @include space(pv500);
  position: relative;
  z-index: 1;
  @include bp(desktopUp) {
    @include space(pa500);
    @include space(pv600);
  }
}

.ck-footer-wrap {
  margin-left: auto;
  margin-right: auto;
  max-width: $mwidth-h;
}
.ck-footer-cta {
  @include type(400);
  @include bp(desktopUp) {
    margin-left: 25%;
    margin-right: 11%;
  }
}
.ck-footer-title {
  @include space(mb300);
}

.ck-footer-sides {
  @include type(300);
  @include space(mt500);
  @include bp(desktopUp) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 25%;
    margin-right: 11%;
  }
}
.ck-footer-side {
  &.ck-footer-side--lx {
  }
  &.ck-footer-side--rx {
    // flex: 0 0 11%;
    @include bp(phone) {
      @include space(mt400);
    }
  }
}

.ck-footer-partners {
  display: block;
}

.ck-footer-partner {
  @include space(mr400);
  @include space(mv200);
  display: inline-block;
  @include bp(desktopUp) {
    @include space(ml400);
    @include space(mr000);
    text-align: right;
  }
}

.ck-footer-partners-wrap {
  @include space(mt300);
  @include space(pt300);
  border-top: 1px solid white;
}
