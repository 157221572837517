@use "../mixins/breakpoint" as *;
@use '../mixins/space' as *;
@use '../mixins/type' as *;

.ck-layout--objects {
  @include space(pb500);
  @include bp(desktopUp) {
    @include space(pb600);
  }
  .ck-wrap {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .ck-body {
    flex: 1 1 89%;
  }
  .ck-objects {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .ck-object {
    @include space(mb500);
    flex: 0 0 45%;
    line-height: 0;
    position: relative;
    .ck-tile {
      display: inline-flex;
    }
    @include bp(tabletUp) {
      &:nth-child(2n) {
        text-align: right;
      }
      &:nth-child(2n + 1) {
        .ck-tile {
          width: 66%;
        }
      }
      &:nth-child(4n + 3) {
        text-align: right;
        .ck-tile {
          width: 100%;
        }
      }
      &:nth-child(4n + 4) {
        text-align: right;
        .ck-tile {
          width: 66%;
        }
      }
    }
    @include bp(desktopUp) {
      // flex: 0 0 28.1%;
    }
  }
}
